import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from "react-redux";
import store from './store/index';
//import CookieConsent from "react-cookie-consent";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
 

   <Provider store={store}>
    

       <BrowserRouter>
        
         <App /> 
         
      </BrowserRouter>
   </Provider>
  </React.StrictMode>
);

 
